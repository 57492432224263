import { getActiveSpan } from '@sentry/browser';
import { spanToJSON } from '@sentry/core';

// アクティブなSentryのナビゲーションまたはページロードのSpanを終了します。
export function endNavigationOrPageloadSpan(): void {
  const span = getActiveSpan();
  if (!span) return;
  const { op } = spanToJSON(span);
  if (op === 'navigation' || op === 'pageload') {
    span.end();
  }
}
