import { Component, State, Vue } from 'nuxt-property-decorator';
import { StaticData } from '@/utils/searchUtils';
import { State as MyState } from '@/store';
import { generateSearchRoute } from '@/utils/routeUtils';
import { SearchOption, SearchQueryType, PartialSearchQuery } from '@/types/SearchQuery';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';

@Component
export default class SearchNavigationTab extends Vue {
  /** URLパラメータからロードされるクエリ */
  @State((state: MyState) => state.search.query) storedQuery!: PartialSearchQuery;

  /** use storedOption, do not use this directly */
  @State((state: MyState) => state.search.orderId) storedOrderId!: number;
  /** use storedOption, do not use this directly */
  @State((state: MyState) => state.persistent.perPage) storedPerPage!: number;

  /** URLパラメータとLocalStorageからロードされる検索オプション */
  get storedOption(): SearchOption {
    return {
      n: this.storedPerPage,
      o: this.storedOrderId,
    };
  }

  /** URLパラメータからロードされるtypeクエリ (e.g. 'book') */
  get storedTypeQuery(): SearchQueryType | null {
    if (!this.storedQuery.type) {
      return null;
    }

    return this.storedQuery.type[0];
  }

  /** 有効なサービス一覧 */
  get services(): { type: SearchQueryType; name: string; to: ReturnType<typeof generateSearchRoute> | string }[] {
    const services: SearchQueryType[] = this.$auth.permissions.legalweb
      ? [DocumentTypeEnum.Book, DocumentTypeEnum.Guideline, DocumentTypeEnum.Pubcom, DocumentTypeEnum.Law]
      : [DocumentTypeEnum.Book];

    return services.map((type) => ({
      type,
      name: StaticData.types.display[type],
      to:
        type === this.storedTypeQuery
          ? this.$route.fullPath
          : /** 書籍と書籍以外ではpublisherクエリを使いまわせないのでリセットする */
          type === DocumentTypeEnum.Book || this.storedTypeQuery === DocumentTypeEnum.Book
          ? generateSearchRoute({ ...this.storedQuery, type: [type], publisher: undefined }, this.storedOption)
          : generateSearchRoute({ ...this.storedQuery, type: [type] }, this.storedOption),
    }));
  }
}
